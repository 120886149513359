import { useState, useEffect, useCallback } from "react";
import type { ChangeEvent, FormEvent, InputHTMLAttributes } from "react";
import { motion } from "framer-motion";
import { IconSend } from "@tabler/icons-react";
import { cn } from "@/utils/helpers";
import { Button } from "./Button";
import { db } from "@/lib/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
  challengeAnswer: string;
}

interface Challenge {
  question: string;
  answer: string;
  type: "math" | "word" | "pattern";
  hint?: string;
}

const initialFormData: FormData = {
  name: "",
  email: "",
  subject: "",
  message: "",
  challengeAnswer: "",
};

// Generate a simple math challenge (only addition with small numbers)
const generateMathChallenge = (): Challenge => {
  const num1 = Math.floor(Math.random() * 5) + 1; // 1-5
  const num2 = Math.floor(Math.random() * 5) + 1; // 1-5
  const answer = (num1 + num2).toString();

  return {
    type: "math",
    question: `What is ${num1} + ${num2}?`,
    answer,
    hint: "Enter the number",
  };
};

// Generate a simple word challenge
const generateWordChallenge = (): Challenge => {
  const challenges = [
    {
      question: "What color is the sky on a clear day?",
      answer: "blue",
    },
    {
      question: "What color is grass?",
      answer: "green",
    },
    {
      question: "Is ice hot or cold?",
      answer: "cold",
    },
    {
      question: "What number comes after four?",
      answer: "5",
    },
  ];

  const selected = challenges[Math.floor(Math.random() * challenges.length)];
  return {
    type: "word",
    question: selected.question,
    answer: selected.answer.toLowerCase(),
    hint: "Type your answer in lowercase",
  };
};

// Generate a random challenge
const generateChallenge = (): Challenge => {
  // 50/50 chance between math and word challenges
  return Math.random() < 0.5
    ? generateMathChallenge()
    : generateWordChallenge();
};

export function ContactSection() {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: "idle" | "success" | "error";
    message?: string;
  }>({ type: "idle" });
  const [challenge, setChallenge] = useState<Challenge>(generateChallenge());
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [nextAllowedSubmit, setNextAllowedSubmit] = useState<Date | null>(null);

  // Generate a new challenge when the component mounts
  useEffect(() => {
    setChallenge(generateChallenge());
  }, []);

  // Input validation
  const validateInputs = (data: FormData) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      throw new Error("Please enter a valid email address");
    }
    if (data.name.length < 2 || data.name.length > 50) {
      throw new Error("Name must be between 2 and 50 characters");
    }
    if (data.subject.length < 3 || data.subject.length > 100) {
      throw new Error("Subject must be between 3 and 100 characters");
    }
    if (data.message.length < 10 || data.message.length > 1000) {
      throw new Error("Message must be between 10 and 1000 characters");
    }
  };

  // Sanitize input
  const sanitizeInput = (str: string) => {
    return str.trim().replace(/[<>]/g, "");
  };

  // Handle challenge verification with exponential backoff
  const handleChallengeVerification = useCallback(() => {
    if (nextAllowedSubmit && new Date() < nextAllowedSubmit) {
      const waitSeconds = Math.ceil(
        (nextAllowedSubmit.getTime() - new Date().getTime()) / 1000
      );
      throw new Error(`Please wait ${waitSeconds} seconds before trying again`);
    }

    const userAnswer = formData.challengeAnswer.toLowerCase().trim();
    if (userAnswer !== challenge.answer.toLowerCase()) {
      setFailedAttempts((prev) => prev + 1);
      // Exponential backoff: 2^n seconds (2, 4, 8, 16...)
      const backoffSeconds = Math.min(Math.pow(2, failedAttempts), 30);
      const nextAllowed = new Date(Date.now() + backoffSeconds * 1000);
      setNextAllowedSubmit(nextAllowed);
      throw new Error(
        `Incorrect answer. Please wait ${backoffSeconds} seconds before trying again.`
      );
    }

    return true;
  }, [
    challenge.answer,
    formData.challengeAnswer,
    failedAttempts,
    nextAllowedSubmit,
  ]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: "idle" });

    try {
      // Validate challenge
      handleChallengeVerification();

      // Validate inputs
      validateInputs(formData);

      // Sanitize inputs
      const sanitizedData = {
        name: sanitizeInput(formData.name),
        email: sanitizeInput(formData.email),
        subject: sanitizeInput(formData.subject),
        message: sanitizeInput(formData.message),
        timestamp: serverTimestamp(),
      };

      // Add document to Firestore
      await addDoc(collection(db, "contact-messages"), sanitizedData);

      setSubmitStatus({
        type: "success",
        message: "Message sent successfully! We'll get back to you soon.",
      });
      setFormData(initialFormData);
      setChallenge(generateChallenge()); // Generate new challenge
      setFailedAttempts(0); // Reset failed attempts
      setNextAllowedSubmit(null); // Reset cooldown
    } catch (error) {
      console.error("Error sending message:", error);
      if (error instanceof Error) {
        setSubmitStatus({
          type: "error",
          message: error.message,
        });
      } else {
        setSubmitStatus({
          type: "error",
          message: "An unexpected error occurred. Please try again later.",
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <motion.section
      id="contact"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="py-16 px-4 relative"
    >
      <div className="max-w-6xl mx-auto">
        <div className="backdrop-blur-sm border border-white/10 rounded-2xl p-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-3xl font-light text-white mb-3">
                Get in Touch
              </h2>
              <p className="text-neutral-400 mb-6 font-light">
                Our team is ready to provide you with detailed information, or
                tailored solutions to meet your specific needs. We'd love to
                hear from you! Send us a message with your inquiries, and we'll
                get back to you as soon as possible.
              </p>
              <p className="text-neutral-400 mb-6 font-light">
                Your success is our priority, and we're committed to ensuring
                you have all the tools and knowledge to make the most of our
                innovative OCR solutions. We'd love to hear from you!
              </p>
            </div>

            <div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <InputField
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    maxLength={50}
                  />
                  <InputField
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <InputField
                  name="subject"
                  placeholder="Subject"
                  type="text"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  maxLength={100}
                />
                <textarea
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  required
                  maxLength={1000}
                  className={cn(
                    "w-full rounded-lg bg-white/5 border border-white/10 text-white placeholder-neutral-500",
                    "focus:border-white/20 focus:ring-0 focus:outline-none",
                    "p-3 transition-colors"
                  )}
                />

                {/* Challenge Question */}
                <div className="space-y-2">
                  <label className="block text-sm text-neutral-300">
                    {challenge.question}
                    {challenge.hint && (
                      <span className="text-neutral-500 ml-2 text-xs">
                        ({challenge.hint})
                      </span>
                    )}
                  </label>
                  <InputField
                    name="challengeAnswer"
                    placeholder="Enter your answer"
                    type="text"
                    value={formData.challengeAnswer}
                    onChange={handleChange}
                    required
                    className="w-48"
                  />
                </div>

                <Button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    (nextAllowedSubmit !== null &&
                      new Date() < nextAllowedSubmit)
                  }
                  className="!bg-white/10 hover:!bg-white/20 backdrop-blur-sm"
                >
                  <span className="flex items-center gap-2">
                    {isSubmitting ? "Sending..." : "Send Message"}
                    <IconSend className="w-4 h-4" />
                  </span>
                </Button>

                {submitStatus.type === "success" && (
                  <p className="text-green-400 text-sm mt-2">
                    {submitStatus.message}
                  </p>
                )}

                {submitStatus.type === "error" && (
                  <p className="text-red-400 text-sm mt-2">
                    {submitStatus.message}
                  </p>
                )}
              </form>
            </div>

            {/* Background gradient */}
            <div className="absolute bottom-0 mt-[2px] flex h-8 items-end overflow-hidden">
              <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

function InputField({
  type = "text",
  placeholder,
  className,
  value,
  onChange,
  required,
  name,
  ...props
}: InputFieldProps) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className={cn(
        "w-full rounded-lg bg-white/5 border border-white/10 text-white placeholder-neutral-500",
        "focus:border-white/20 focus:ring-0 focus:outline-none",
        "p-3 transition-colors",
        className
      )}
      value={value}
      onChange={onChange}
      required={required}
      name={name}
      {...props}
    />
  );
}
