import React from "react";

export const Logo = ({
  width = "120",
  height = "32",
  className = "",
  style = {},
  theme = "dark", // Default to dark theme for the landing page
}: {
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  theme?: "light" | "dark";
}) => {
  const logoSrc = `/images/logo/${theme === "dark" ? "logo-light.webp" : "logo-dark.webp"}`;

  return (
    <img
      src={logoSrc}
      alt="OCRIntel Logo"
      style={{
        width: typeof width === "number" ? `${width}px` : width,
        height: typeof height === "number" ? `${height}px` : height,
        maxHeight: typeof height === "number" ? `${height}px` : height,
        objectFit: "contain",
        ...style,
      }}
      className={className}
    />
  );
};
