import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IconMenu2, IconX } from "@tabler/icons-react";
import { cn } from "@/utils/helpers";
import { Logo } from "./Logo";
import { Button } from "./Button";
import { ROUTES } from "@/constants/routes";

export const FloatingNav = ({
  navItems,
  className,
}: {
  navItems: {
    name: string;
    link: string;
    icon?: React.ReactNode;
  }[];
  className?: string;
}) => {
  const [active, setActive] = useState<string>(navItems[0]?.name);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Update active state based on current URL
    const path = window.location.pathname;
    const hash = window.location.hash;
    const currentNav = navItems.find((item) => {
      // Check if the path matches exactly or if it's a hash link
      if (item.link === path || (hash && item.link === hash)) {
        return true;
      }
      // Check if the path starts with the item link (for nested routes)
      if (item.link !== "/" && path.startsWith(item.link)) {
        return true;
      }
      return false;
    });
    if (currentNav) {
      setActive(currentNav.name);
    }
  }, [navItems]);

  const handleNavigate = (
    e: React.MouseEvent<HTMLAnchorElement>,
    item: (typeof navItems)[0]
  ) => {
    if (item.link.startsWith("#")) {
      e.preventDefault();
      setActive(item.name);
      setIsMobileMenuOpen(false);

      // Internal hash navigation on current page
      const element = document.querySelector(item.link);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={cn(
          "fixed top-5 inset-x-0 max-w-2xl mx-auto z-50",
          className
        )}
      >
        <nav className="relative rounded-sm overflow-hidden group">
          <div className="absolute inset-0 overflow-hidden rounded-sm">
            <span className="absolute inset-0 rounded-sm bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
          <div className="relative flex w-full items-center justify-between px-4 py-2 bg-zinc-950/80 backdrop-blur-md rounded-sm shadow-lg shadow-zinc-900/50 z-10 ring-1 ring-white/10">
            <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-blue-400/0 via-blue-400/90 to-blue-400/0 transition-opacity duration-500 group-hover:opacity-40" />
            <div className="flex items-center justify-between w-full md:w-auto md:space-x-6">
              <a href="/" className="flex items-center">
                <Logo
                  height="32"
                  width="auto"
                  className="text-neutral-900 dark:text-white"
                  style={{ maxHeight: "32px" }}
                />
              </a>
              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center space-x-6">
                {navItems.map((item) => (
                  <a
                    key={item.name}
                    href={item.link}
                    onClick={(e) => handleNavigate(e, item)}
                    className={cn(
                      "relative px-3 py-2 text-sm font-extralight text-white hover:text-white/80 transition-colors",
                      active === item.name && "text-white"
                    )}
                  >
                    <span className="relative z-10 flex items-center space-x-2">
                      {item.icon && <span>{item.icon}</span>}
                      <span>{item.name}</span>
                    </span>
                    {active === item.name && (
                      <motion.div
                        layoutId="indicator"
                        className="absolute inset-0 bg-white/10 rounded-sm z-0"
                        initial={false}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 30,
                        }}
                      />
                    )}
                  </a>
                ))}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Button
                onClick={() => {
                  window.location.href = ROUTES.APP_LOGIN;
                }}
                className="!bg-white/10 hover:!bg-white/20"
                withArrow={false}
              >
                Login
              </Button>
              {/* Mobile Menu Button */}
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="md:hidden text-white p-2 hover:bg-white/10 rounded-sm transition-colors"
              >
                {isMobileMenuOpen ? (
                  <IconX className="w-6 h-6" />
                ) : (
                  <IconMenu2 className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </nav>
      </motion.div>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed inset-x-0 top-[5.5rem] p-4 bg-zinc-950/95 backdrop-blur-lg z-40 border-t border-white/10 shadow-lg md:hidden"
          >
            <div className="flex flex-col space-y-4 items-center">
              {navItems.map((item) => (
                <a
                  key={item.name}
                  href={item.link}
                  onClick={(e) => handleNavigate(e, item)}
                  className={cn(
                    "w-full text-center px-4 py-3 text-sm font-extralight text-white hover:bg-white/10 rounded-sm transition-colors",
                    active === item.name && "bg-white/10"
                  )}
                >
                  <span className="flex items-center justify-center space-x-2">
                    {item.icon && <span>{item.icon}</span>}
                    <span>{item.name}</span>
                  </span>
                </a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
