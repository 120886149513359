import { initializeApp, getApps } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  limit,
} from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Debug logging function
const logDebug = (msg: string, data?: any) => {
  console.log(`[Firebase Debug] ${msg}`, data || "");
};

// Initialize Firebase
let app;
if (getApps().length === 0) {
  logDebug("Initializing new Firebase app");
  app = initializeApp(firebaseConfig);
} else {
  logDebug("Using existing Firebase app");
  app = getApps()[0];
}

// Initialize Firestore
const db = getFirestore(app);

// Debug function to test Firebase connectivity
export const testFirebaseConnection = async () => {
  try {
    logDebug("Testing Firebase configuration:", {
      projectId: firebaseConfig.projectId,
      authDomain: firebaseConfig.authDomain,
    });

    if (!firebaseConfig.projectId) {
      throw new Error("Project ID is missing from Firebase configuration");
    }

    logDebug("Testing Firestore connectivity...");
    const blogRef = collection(db, "blog-posts");
    const q = query(blogRef, limit(1));

    logDebug("Attempting to fetch one document...");
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      logDebug("No documents found in blog-posts collection");
      return {
        success: true,
        hasAccess: true,
        message: "Connected successfully but collection is empty",
      };
    }

    logDebug("Successfully fetched document:", {
      documentId: snapshot.docs[0].id,
      exists: snapshot.docs[0].exists(),
    });

    return {
      success: true,
      hasAccess: true,
      message: "Successfully connected and retrieved data",
    };
  } catch (error: any) {
    logDebug("Error during connection test:", error);

    let errorType = "unknown";
    if (error.code === "permission-denied") {
      errorType = "permissions";
    } else if (error.code === "not-found") {
      errorType = "missing-collection";
    } else if (error.message?.includes("projectId")) {
      errorType = "config";
    }

    return {
      success: false,
      hasAccess: false,
      errorType,
      message: error.message,
      code: error.code,
    };
  }
};

export { db };
